<template>
  <li>
    <item-card>    
      <template v-slot:content>
        <item-text title="ID" :text="item.item_id" />
        <item-text title="Preces kods" :text="item.item.sku" />
        <item-text title="Nosaukums" :text="item.item.name" />
        <item-text title="Modelis" :text="item.item.model" />
        <item-text title="Daudzums" :text="item.quantity" />
      </template>
    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"

export default {
  name: "SingleTehnicalDocumentDetails",
  components: {
    ItemCard,
    ItemText,
  },
  props: ["item"],
}
</script>

<style>

</style>